const Colors = {
  green: "#9457eb ",
  blue: "#0038a8",
  black: "#3b3c3d",
  white: "#e8eaeb",
  red: "#dc2649",
  indigo: "#510560",
  violet: "#253745",
};

export default Colors;
